<template>
  <v-dialog
    v-model="isOpen"
    @click:outside="$emit('onClose')"
    persistent
    max-width="800px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab style="display:flex;flex-direction:column;">
        <span style="font-size:1.2rem;">
          Historique - {{ documentType.NomTypeDocument }}
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="$emit('onClose')">mdi-close</v-icon>
    </v-tabs>

    <div class="modal__content">
      <v-tabs-items>
        <v-row class="p-4">
          <v-col v-if="!mounted" class="text-center">
            <v-progress-circular
              indeterminate
              color="indigo darken-4"
            ></v-progress-circular>
          </v-col>

          <v-col v-else>
            <v-row
              no-gutters
              v-for="(document, index) in documents"
              :key="index"
            >
              <ItemHistory :context="context" :document="document" />
            </v-row>
          </v-col>
        </v-row>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import ItemHistory from "@/components/utils/modals/Documents/Document__HistoryItem.vue";

export default {
  components: { ItemHistory },

  data: () => ({
    documents: [],
    mounted: false,
    modalPreviewIsOpen: false,
  }),

  async created() {
    this.documents = await this.getDocumentStructureByType({
      ID_Structure: this.context.ID_Structure,
      ID_DocumentType: this.context.documentType.id,
    });
    this.mounted = true;
  },

  computed: {
    documentType() {
      return this.context.documentType;
    },
  },

  methods: {
    ...mapActions("structure", ["getDocumentStructureByType"]),
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.modal__content {
  overflow-x: hidden;
}
</style>
<style scoped>
hr {
  margin: 0;
  padding: 0;
  width: 100%;
}
</style>
